
import Vue from 'vue';
import LoggedIn from './LoggedIn.vue';
import { LoginService } from '@/services/login';
import { Notification } from '@/services/notification';
import Team from './Settings/Team.vue';
import CodeSettings from './Settings/Code.vue';
export default Vue.extend({
  components: {
    LoggedIn,
    Team,
    CodeSettings,
  },
});
