
import BottomBar from '@/components/Layout/BottomBar.vue';
import Vue from 'vue';
import Toolbar from './Toolbar.vue';
export default Vue.extend({
  name: 'LoggedIn',
  props: ['top'],
  components: {
    Toolbar,
    BottomBar,
  },
});
