
import Vue from 'vue';
import TeamSelection from '@/components/Settings/TeamSelection.vue';
import { Team } from '@/models/Organization';
import { Notification } from '@/services/notification';
export default Vue.extend({
  components: { TeamSelection },
  computed: {
    hasTeam() {
      return !!this.$store.getters.team;
    },
    teamName() {
        const team: Team = this.$store.getters.team;
        return team.properties.name;
    },
    hasTeams() {
      return !!this.$store.getters.regionMap;
    }
  },
  methods: {
      leaveTeam() {
          this.$store.commit('setTeam', null);
          Notification.methods.send(true, 'Success', 'Left team');
      }
  }
});
