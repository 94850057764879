
import { LoginService } from '@/services/login';
import { Notification } from '@/services/notification';
import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      code: this.$store.getters.countID,
    };
  },
  methods: {
    async changePITCode(e: any) {
      e.preventDefault();
      const result = await LoginService.methods.preLogin(
        this.code.toUpperCase(),
        this.$store.getters.volunteerName,
        this.$store.getters.volunteerPhone
      );
      if (!result) {
        Notification.methods.send(false, 'Invalid Code', '');
      } else {
        Notification.methods.send(true, 'Code updated', '');
      }
    },
  },
});
