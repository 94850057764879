
import Vue from 'vue';
export default Vue.extend({
  data: () => ({
    routes: [
      {
        text: 'Survey',
        icon: 'assignment',
        name: '/home',
      },
      {
        text: 'Map',
        icon: 'map',
        name: '/map',
      },
      {
        text: 'Settings',
        icon: 'mdi-settings',
        name: '/settings',
      },
    ],
  }),
});
