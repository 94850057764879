
import { ClientEvent, RegionMap } from '@/models/count';
import { FirebaseService } from '@/services/firebase';
import { Notification } from '@/services/notification';
import Vue from 'vue';
export default Vue.extend({
  data() {
    return {
      team: null as any,
    };
  },
  computed: {
    teams() {
      const map: RegionMap = this.$store.getters.regionMap;
      if (!map) return [];
      return map.regions.features;
    },
  },
  methods: {
    async saveTeam(e: Event) {
      e.preventDefault();
      try {
        const event: ClientEvent = {
          type: 'team',
          date: Date.now(),
          team: this.team?.properties?.name,
          identifier: this.$store.getters.identifier,
          volunteerName: this.$store.getters.volunteerName,
          volunteerPhone: this.$store.getters.volunteerPhone,
        };
        const result = await FirebaseService.newEvent(
          event,
          this.$store.getters.organizationID,
          this.$store.getters.countID
        );
        if (!result) throw {};
        this.$store.commit('setTeam', this.team);
        Notification.methods.send(true, 'Success', 'Team updated');
      } catch (err) {
        Notification.methods.send(false, 'Error', 'Something went wrong');
      }
    },
  },
});
