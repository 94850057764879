import { Notification } from './notification';
import { FirebaseService } from './firebase';
import { CountService } from './count';
export const LoginService = {
  methods: {
    async preLogin(
      countID: string,
      name: string,
      phone: string
    ): Promise<boolean> {
      const result = await CountService.setCountAndLoginEvent(countID, name, phone);
      return result;
    },
  },
};
