
import Vue from "vue";

export default Vue.extend({
        name: "toolbar",
    methods: {
        logout() {
            this.$store.commit('clearUser');
            this.$router.push({ path: '/login' });
        },
    }
});
